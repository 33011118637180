import React, { useState } from 'react';
import { DropDown, DropDownContent, DropDownLocations, MenuCard } from './DropDown.css';

const Tooltip = ({
	children,
	content,
	location = 'topLeft',
}: {
	children: React.ReactNode;
	content?: React.ReactNode;
	location?: DropDownLocations;
}) => {
	const [open, setOpen] = useState(false);

	return (
		<div onMouseLeave={() => setOpen(false)} className={DropDown}>
			<div
				onMouseEnter={() => setOpen(content != null && true)}
				onClick={() => setOpen(!open)}
			>
				{children}
			</div>
			{open && (
				<div
					style={{
						minWidth: '200px',
						maxWidth: '350px',
						padding: '16px',
						boxSizing: 'border-box',
					}}
					className={DropDownContent({ location })}
				>
					<div className={MenuCard}>{content}</div>
				</div>
			)}
		</div>
	);
};

export default Tooltip;

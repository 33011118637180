import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import ExternalLink from '../components/Link';
import { sprinkles } from '../sprinkles.css';
import { vars } from '../theme.css';
import { ButtonRecipe } from './Button.css';
import {
	CheckCircle as CheckCircleIcon,
	CloseCircle as CloseCircleIcon,
	QuestionCircle,
} from './Icon';
import { Section } from './Layout';
import {
	HeaderItemStyle,
	HeaderRowStyle,
	ItemStyle,
	MobileStickyHeaderStyle,
	RowStyle,
} from './PricingTable.css';
import { Text, Title } from './Text';
import Tooltip from './Tooltip';
import { usePlausible } from './Tracker';

const Header = ({ data }: { data: Queries.PricingHeader }) => {
	const {
		i18n: { language },
	} = useTranslation();
	const plausible = usePlausible();
	const [offsetTop, setOffsetTop] = useState<number>(1);
	const ref = useRef<HTMLDivElement>(null);
	const [stickyHeader, setStickyHeader] = useState(false);

	const onScroll = () => {
		if (ref.current) {
			const rect = ref.current.getBoundingClientRect();
			setOffsetTop(rect.top);
			if (rect.top === 0) setStickyHeader(true);
			else if (rect.top > 150) setStickyHeader(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	return (
		<>
			<div
				ref={ref}
				className={HeaderRowStyle}
				style={{
					paddingTop: stickyHeader ? 16 : 0,
					paddingBottom: stickyHeader ? 16 : 0,
					flexDirection: stickyHeader ? 'row' : undefined,
				}}
			>
				<div className={HeaderItemStyle}>
					<Section align="start" width="auto">
						<Text>{data?.title}</Text>
						{/* <Text>
							{language === 'en' ? (
								<>
									<strong>Autumn-Deal</strong> until 11/30
								</>
							) : (
								<>
									<strong>Herbst-Deal</strong> bis 30.11
								</>
							)}
						</Text> */}
					</Section>
				</div>
				<div className={HeaderItemStyle}>
					<Section align="start" width="auto">
						<Text strong>
							{data?.business?.title}{' '}
							<span className={sprinkles({ color: 'textLight50', fontSize: 'tiny' })}>
								{data?.business?.upper || '*'}
							</span>
						</Text>
						<Title level="paragraph">{data?.consult?.price}</Title>
						{!stickyHeader && (
							<Text variant="secondary">{data?.business?.description}</Text>
						)}
					</Section>
					<ExternalLink
						to="https://my.meetergo.com/martinmeng/hinschg"
						target="_blank"
						className={ButtonRecipe({
							variant: 'primary',
							size: stickyHeader ? 'small' : 'medium',
						})}
						onClick={() => {
							plausible && plausible.trackEvent('business');
							return true;
						}}
					>
						{data?.business?.button}
					</ExternalLink>
				</div>
				<div className={HeaderItemStyle}>
					<Section align="start">
						<Text strong>
							{data?.enterprise?.title}{' '}
							<span className={sprinkles({ color: 'textLight50', fontSize: 'tiny' })}>
								{data?.enterprise?.upper || '*'}
							</span>
						</Text>
						<Title level="paragraph">{data?.consult?.price}</Title>
						{!stickyHeader && (
							<Text variant="secondary">{data?.enterprise?.description}</Text>
						)}
					</Section>
					<ExternalLink
						to="https://my.meetergo.com/martinmeng/hinschg"
						target="_blank"
						className={ButtonRecipe({
							variant: 'primary',
							size: stickyHeader ? 'small' : 'medium',
						})}
						onClick={() => {
							plausible && plausible.trackEvent('enterprise');
							return plausible != null;
						}}
					>
						{data?.enterprise?.button}
					</ExternalLink>
				</div>
				<div className={HeaderItemStyle}>
					<Section align="start">
						<Text strong>
							{data?.consult?.title}{' '}
							<span className={sprinkles({ color: 'textLight50', fontSize: 'tiny' })}>
								{data?.consult?.upper}
							</span>
						</Text>
						<Title level="paragraph">{data?.consult?.price} </Title>
						{!stickyHeader && (
							<Text variant="secondary">{data?.consult?.description}</Text>
						)}
					</Section>
					<ExternalLink
						to="mailto:sales@konfidal.eu"
						className={ButtonRecipe({
							variant: 'primary',
							size: stickyHeader ? 'small' : 'medium',
						})}
						onClick={() => {
							plausible && plausible.trackEvent('consult');
							return plausible != null;
						}}
					>
						{data?.consult?.button}
					</ExternalLink>
				</div>
			</div>
			<div className={MobileStickyHeaderStyle}>
				<div className={sprinkles({ width: 'fill' })}>
					<Text strong>{data?.business?.title}</Text>
				</div>
				<div className={sprinkles({ width: 'fill' })}>
					<Text strong>{data?.enterprise?.title}</Text>
				</div>
				<div className={sprinkles({ width: 'fill' })}>
					<Text strong>{data?.consult?.title}</Text>
				</div>
			</div>
		</>
	);
};

const Footer = ({ data }: { data?: readonly (string | null)[] | null }) => {
	return (
		<Section width="narrow">
			<Text variant="secondary">{data}</Text>
		</Section>
	);
};

const UncheckCircle = () => (
	<CloseCircleIcon
		style={{ display: 'inline' }}
		fill={vars.colors.textLight25}
		width="1em"
		height="1em"
	/>
);

const CheckCircle = () => (
	<CheckCircleIcon
		style={{ display: 'inline' }}
		fill={vars.colors.primaryLight}
		width="1em"
		height="1em"
	/>
);

const FeatureRowItem = ({
	checked,
	text,
	heading,
}: {
	checked?: boolean | null;
	text?: string | null;
	heading?: string;
}) => (
	<div className={ItemStyle}>
		{/* <Section wrap> */}
		{/* <div
				className={sprinkles({
					width: 'fill',
					display: { mobile: 'block', tablet: 'none' },
				})}
			>
				<Text variant="secondary">{heading}</Text>
			</div> */}
		<Text>
			{checked && <CheckCircle />}
			{!checked && !text && <UncheckCircle />}
			{text}
		</Text>
		{/* </Section> */}
	</div>
);

const FeatureRow = ({ data }: { data?: Queries.PricingSectionFeature }) => {
	return (
		<div className={RowStyle}>
			<div className={ItemStyle} style={{ width: '100%' }}>
				<Section direction="horizontal" justify="left">
					{data?.description != null && (
						<Tooltip
							content={
								data.description !== '' ? (
									<Text variant="secondary">{data?.description}</Text>
								) : null
							}
						>
							<Text strong>
								{data?.title}{' '}
								{data.description !== '' && (
									<QuestionCircle size="1em" fill={vars.colors.textLight25} inline />
								)}
							</Text>
						</Tooltip>
					)}
				</Section>
			</div>
			<FeatureRowItem {...data?.business} heading="Business" />
			<FeatureRowItem {...data?.enterprise} heading="Enterprise" />
			<FeatureRowItem {...data?.consult} heading="Consult" />
		</div>
	);
};

const FeatureSection = ({
	title,
	description,
	features,
}: {
	title?: string | null;
	description?: string | null;
	features?: readonly Queries.PricingSectionFeature[];
}) => {
	return (
		<Section width="fill" direction="vertical" gap="large">
			<Title align="left" level="paragraph">
				{title}
			</Title>
			{features?.map((feature, i) => <FeatureRow key={i} data={feature} />)}
		</Section>
	);
};

const PricingTable = ({ data }: { data: Queries.PricingJson }) => {
	const { t } = useTranslation('PricingTable');

	return (
		<Section width="wide" gap="huge">
			<Header data={data.header} />
			{data.sections?.map((section, i) => (
				<FeatureSection
					key={i}
					features={section?.features}
					title={section?.title}
					description={section?.description}
				/>
			))}
			<Footer data={data.footer} />
		</Section>
	);
};

export default PricingTable;

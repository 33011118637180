import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ButtonRecipe } from '../components/Button.css';
import { ArrowRight } from '../components/Icon';
import Layout, { Section } from '../components/Layout';
import ExternalLink from '../components/Link';
import PricingTable from '../components/PricingTable';
import { Seo } from '../components/Seo';
import { Text, Title } from '../components/Text';
import { usePlausible } from '../components/Tracker';
import { sprinkles } from '../sprinkles.css';
import { width } from '../theme.css';

const PricingPage = ({
	data,
}: {
	data: {
		pricing: {
			edges: Queries.PricingJsonEdge[];
		};
	};
}) => {
	const { t } = useTranslation('PricingPage');
	const plausible = usePlausible();
	const free = data.pricing.edges[0].node?.free;
	const isMobile = useMediaQuery({
		maxWidth: width.tablet,
	});
	return (
		<Layout limitWidth>
			<Section width="wide" align="start">
				<Title align="left" level="title">
					{t('Title')}
				</Title>
				<Text>
					<Trans i18nKey="Slogan1_html" />
				</Text>
			</Section>
			<Section
				width="wide"
				align="start"
				gap="large"
				padding
				className={sprinkles({
					borderColor: 'border',
					borderStyle: 'solid',
					borderWidth: '1px',
					borderRadius: 'medium',
				})}
			>
				<Section
					direction="horizontal"
					justify="between"
					gap="large"
					align="center"
					width="wide"
					wrap
				>
					<Title level="section" style={{ width: 'auto' }}>
						{t('FullService.Title')}
					</Title>
					<div>
						<strong>{t('FullService.Subtitle')}</strong>
					</div>
					<ExternalLink
						style={{ marginLeft: 'auto' }}
						to={t('FullService.CtaLink')}
						className={ButtonRecipe({ size: 'medium', variant: 'primary' })}
					>
						{t('FullService.CtaLabel')}
					</ExternalLink>
				</Section>
				<Text>
					<Trans i18nKey="FullService.Description_html" />
				</Text>
				<Section
					direction="horizontal"
					justify="left"
					align="end"
					width="fill"
					wrap={isMobile}
				>
					<Section align="start" gap="medium">
						<Title level="4">
							<Trans i18nKey="FullService.BulletsTitle_html" />
						</Title>
						<Section width="fill" direction="horizontal" justify="left" gap="medium">
							<ArrowRight style={{ minWidth: '32px' }} width="1.25em" height="1.25em" />
							<Text>
								<Trans i18nKey="FullService.Bullets.Software_html" />
							</Text>
						</Section>
						<Section width="fill" direction="horizontal" justify="left" gap="medium">
							<ArrowRight style={{ minWidth: '32px' }} width="1.25em" height="1.25em" />
							<Text>
								<Trans i18nKey="FullService.Bullets.Service_html" />
							</Text>
						</Section>
						<Section width="fill" direction="horizontal" justify="left" gap="medium">
							<ArrowRight style={{ minWidth: '32px' }} width="1.25em" height="1.25em" />
							<Text>
								<Trans i18nKey="FullService.Bullets.Setup_html" />
							</Text>
						</Section>
					</Section>
					{/* <Section align="end" width="auto" gap="small">
						<span style={{ fontSize: fontSize.huge }}><s>99</s><strong>&nbsp;69</strong>&nbsp;€</span>
						<Text>{t('PerMonth')}</Text>
					</Section> */}
				</Section>
			</Section>
			<Section direction="horizontal" width="wide" align="stretch" gap="medium">
				<Section
					width="wide"
					align="start"
					gap="large"
					style={{
						flexGrow: 2,
					}}
				>
					<Section direction="horizontal" justify="between" width="wide" wrap>
						<Title level="section" style={{ width: 'auto' }}>
							{t('Services.Title')}
						</Title>
						<ExternalLink
							style={{ marginLeft: 'auto' }}
							to={t('Services.CtaLink')}
							className={ButtonRecipe({ size: 'large', variant: 'primaryText' })}
						>
							{t('Services.CtaLabel')}
						</ExternalLink>
					</Section>
					<Text>
						<Trans i18nKey="Services.Description_html" />
					</Text>
					<Title level="4">
						<Trans
							i18nKey={
								isMobile
									? 'Services.BulletsTitleShort_html'
									: 'Services.BulletsTitle_html'
							}
						/>
					</Title>
					<Section align="start" gap="medium">
						<Section width="fill" direction="horizontal" justify="left" gap="medium">
							<ArrowRight style={{ minWidth: '32px' }} width="1.25em" height="1.25em" />
							<Text>
								<Trans i18nKey="Services.Bullets.Consulting_html" />
							</Text>
						</Section>
						<Section width="fill" direction="horizontal" justify="left" gap="medium">
							<ArrowRight style={{ minWidth: '32px' }} width="1.25em" height="1.25em" />
							<Text>
								<Trans i18nKey="Services.Bullets.Teaching_html" />
							</Text>
						</Section>
						<Section width="fill" direction="horizontal" justify="left" gap="medium">
							<ArrowRight style={{ minWidth: '32px' }} height="1.25em" />
							<Text>
								<Trans i18nKey="Services.Bullets.Stichhaltigkeit_html" />
							</Text>
						</Section>
					</Section>
				</Section>
				<StaticImage
					src="../images/martin-leaning-portrait.png"
					alt="Martin Meng - Experte und Berater zum Hinweisgeberschutz"
					objectFit="cover"
					className={sprinkles({ borderRadius: 'medium', maxWidth: 'five' })}
				/>
			</Section>
			<Section width="wide" align="start" gap="large">
				<Title level="section">{t('Software.Title')}</Title>
				<Text>
					<Trans i18nKey="Software.Description_html" />
				</Text>
				<PricingTable data={data.pricing.edges[0].node} />
			</Section>
		</Layout>
	);
};

export const Head = () => {
	return <Seo />;
};

export default PricingPage;

export const pageQuery = graphql`
	query pricingPageQuery($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		pricing: allPricingJson(filter: { language: { eq: $language } }) {
			edges {
				node {
					header {
						title
						free {
							title
							price
							upper
							description
							button
						}
						business {
							title
							price
							upper
							description
							button
						}
						enterprise {
							title
							price
							upper
							description
							button
						}
						consult {
							title
							price
							upper
							description
							button
						}
					}
					sections {
						title
						description
						features {
							title
							description
							free {
								checked
								text
							}
							business {
								checked
								text
							}
							enterprise {
								checked
								text
							}
							consult {
								checked
								text
							}
						}
					}
					footer
					free {
						title
						description
						price
						specification
						button
					}
				}
			}
		}
	}
`;
